<template>
  <div class="auth-wrapper auth-v1">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="deep-purple accent-4"
    ></v-progress-linear>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">SpookApp</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to SpookApp! 👋🏻</p>
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details="auto"
              :rules="emailRules"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :rules="[rules.required, rules.min]"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" class="me-3 mt-1"> </v-checkbox>

              <!-- forgot link -->
              <a href="/pages/forgot" class="mt-1"> Forgot Password? </a>
            </div>

            <v-btn block color="primary" class="mt-6" @click="submitForm"> Login </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'pages-register' }"> Create an account </router-link>
        </v-card-text>

        <!-- divider -->
        <!--         <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'

import { set } from 'firebase/database'

import { collection, addDoc, setDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { dbRef, app, loginGoogle } from '../../../firebaseConfig'
import { setUserData, getUserData, getUserDataInfo } from '../../../localStorage'
import { getNameFromEmail } from '../../../generalUtils'
import store from '@/store'
import router from '@/router'
import { activate, fetchConfig, getRemoteConfig } from 'firebase/remote-config'
import { getValue, fetchAndActivate } from 'firebase/remote-config'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Role } from '@/helper/role'
var CryptoJS = require('crypto-js')
const auth = getAuth()
const analytics = getAnalytics(app)
export default {
  methods: {
    async submitForm() {
      loginGoogle()
      const remoteConfig = getRemoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 10
      remoteConfig.defaultConfig = {
        is_subscribe: 'false',
      }
      await fetchAndActivate(remoteConfig)
        .then(res => {
          console.log(res)
          const vals = getValue(remoteConfig, 'prices')
          console.log(vals)
        })
        .catch(err => {
          console.log(err)
        })
      const vals = getValue(remoteConfig, 'prices')
      console.log(vals)
      /*  fetchConfig()
      activate() */
      console.log(process.env.VUE_APP_CRYPTOJS)
      const result = this.$refs.form.validate()
      var ciphertext = CryptoJS.AES.encrypt('my message', process.env.VUE_APP_CRYPTOJS).toString()

      console.log(ciphertext)
      var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_CRYPTOJS)
      var originalText = bytes.toString(CryptoJS.enc.Utf8)
      console.log(originalText)
      if (result) {
        this.signInWithMail()
        console.log('result')
      } else {
        console.log('offline')
      }
    },
    async signInWithMail() {
      this.$store.commit('LOADER', true)
      let value, errorss

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(userCredential => {
          const { user } = userCredential
          if (!user.displayName) {
            updateProfile(auth.currentUser, {
              displayName: getNameFromEmail(user.email),
            })
              .then(() => {
                // Profile updated!
                // ...
                console.log('Update Name')
                user.displayName = getNameFromEmail(user.email)
              })
              .catch(error => {
                // An error occurred
                // ...
                console.log(error)
                console.log('Update Name failed')
              })
              .finally(res => {
                console.log('finnaly')
                this.$store.commit('LOADER', false)
              })
          }

          if (user.emailVerified) {
            setUserData(user)
            let result = user
            const txt = new Map()
            const roles = new Map()

            const paymentRef = collection(dbRef, 'payment')

            let q = query(paymentRef, where('uid', '==', user.uid))
            getDocs(q)
              .then(paymentRess => {
                let rolesitems = []
                paymentRess.forEach(ress => {
                  console.log(ress.id, ' => ', ress.data())
                  rolesitems.push(ress.data().plan)
                })

                if (rolesitems.length == -1) {
                  roles.set('permission', Role.Basic)
                } else {
                  let platium = rolesitems.find(e => e == 'plantium')
                  let gold = rolesitems.find(e => e == 'gold')
                  let basic = rolesitems.find(e => e == 'basic')
                  if (basic == undefined) {
                    roles.set('permission', Role.Basic)
                  }
                  if (gold != undefined) {
                    roles.set('permission', Role.Gold)
                  }
                  if (platium != undefined) {
                    roles.set('permission', Role.Platinum)
                  }

                  //  roles.set('permission', result.roles)
                }
                txt.set('username', result?.displayName)
                txt.set('roles', Object.fromEntries(roles))
                console.log(Object.fromEntries(txt))
                console.log(JSON.stringify(txt))
                store.commit('change', Object.fromEntries(txt))
                router.push('/dashboard')
              })
              .catch(error => {
                this.swal(false, error.message);
                 this.$store.commit('LOADER', false)
              })
              .finally(res => {
                this.$store.commit('LOADER', false);
              })
          } else {
            value = false
            errorss = 'Email verifed is pending'
            this.swal(value, errorss);
             this.$store.commit('LOADER', false)
          }
        })
        .catch(error => {
          // Handle Errors here.
          // var errorCode = error.code;
          //var errorMessage = error.message;
          value = false

          console.log('error' + error)
          // console.log("errorCode" + errorCode);
          // console.log("errorMessage" + errorMessage);
          errorss = error.message
          this.swal(value, errorss)
           this.$store.commit('LOADER', false);
        })

      this.swal(value, errorss);
       this.$store.commit('LOADER', false)
    },
    async swal(value, error) {
      //  console.log(constant.error.hello);
      if (value == true) {
        if (error == '' || error == undefined) {
          this.$swal.fire('success', 'Email send successfully', 'success')
        } else {
          this.$swal.fire('success', error, 'success')
        }
      } else if (value == null) {
        //  console.log("null");
      } else {
        // console.log("value get" + error);
        if (error == '') {
          //   console.log("null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        } else {
          //  console.log("not null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          })
        }
      }
    },
    async signInWithMailAuth() {
      let user = await this.signInWithMail()
      console.log(user)
      console.log(this.googleResult)

      if (localStorage) {
        localStorage.setItem('emailForSignIn', JSON.stringify(this.googleResult))
      }

      await this.rolesFunction(this.googleResult.user.uid)
      const txt = new Map()
      const roles = new Map()
      if (this.myDBuser == null) {
        roles.set('permission', 1)
      } else {
        roles.set('permission', this.myDBuser.roles)
      }
      txt.set('username', this.googleResult.user.displayName)
      txt.set('roles', Object.fromEntries(roles))
      console.log(Object.fromEntries(txt))
      console.log(JSON.stringify(txt))
      store.commit('change', Object.fromEntries(txt))
      router.push('home')
    },
  },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    valid: false
    loading: true
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      valid: false,
      loading: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 5 || 'Password must be more than 4 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
